/* eslint-disable quote-props */

import { Views } from 'react-big-calendar';

export const EMAIL_VALIDATION_SUCCESS = { AUTHORIZED: 200 };
export const EMAIL_VALIDATION_ERRORS = { SIGN_UP: 422, UNAUTHORIZED: 418 };
export const ACCESS_MATRIX_VALIDATION = { MISSING: 521 };

export const SIGNIN_ERRORS = {
  'auth/invalid-email': 'The email address is badly formatted.',
  'auth/user-disabled': 'The user has been disabled.',
  'auth/user-not-found': 'There is no user record corresponding to this email.',
  'auth/wrong-password': 'Invalid password. Please, try again or reset your password.',
  'auth/argument-error': 'Something happened during the sign in. Please, try again.',
  [EMAIL_VALIDATION_SUCCESS.AUTHORIZED]: 'Accepted',
  [EMAIL_VALIDATION_ERRORS.SIGN_UP]: 'Finish signup and create a password',
  [EMAIL_VALIDATION_ERRORS.UNAUTHORIZED]: 'Finish signup and create a password',
  [ACCESS_MATRIX_VALIDATION.MISSING]: 'No user access matrix found',
};

export const ROLE_ACCESSES = {
  appRole: 'appRole',
  default: 'default', // this includes: 'add to external calendar', 'cancelled courses headers', 'timezone_selection', 'order confirmation'
  ownCalendar: 'ownCalendar',
  bookEvent: 'bookEvent',
  locCalendar: 'locCalendar',
  viewOrdersPanel: 'viewOrdersPanel',
  viewTrainersProfile: 'viewTrainersProfile',
  tciProfile: 'tciProfile',
  editTrainerProfile: 'editTrainerProfile',
  swapInstructor: 'swapInstructor',
  canDeliver: 'canDeliver',
  canManuallyInvoice: 'canManuallyInvoice',
  canCancel: 'canCancel',
  cancelMsg: 'cancelMsg',
  editEvent: 'editEvent',
  editEventMsg: 'editEventMsg',
  showRevenue: 'showRevenue',
  customerDashboard: 'customerDashboard',
  editTimeblock: 'editTimeblock',
  territoryExport: 'territoryExport',
  viewNationalAccountPanel: 'viewNationalAccountPanel',
  subcontractorEvents: 'subcontractorEvents',
  outsideTerritoryNotification: 'outsideTerritoryNotification',
  subcontractorInvMan: 'subcontractorInvMan',
  basicAdmin: 'basicAdmin',
  advancedAdmin: 'advancedAdmin',
};

export const ROLE_ACCESSES_LABELS = {
  [ROLE_ACCESSES.appRole]: 'TC Org Assignment',
  [ROLE_ACCESSES.default]: 'Default',
  [ROLE_ACCESSES.ownCalendar]: 'Own Calendar',
  [ROLE_ACCESSES.bookEvent]: 'Add Event',
  [ROLE_ACCESSES.locCalendar]: 'Location Calendar',
  [ROLE_ACCESSES.viewOrdersPanel]: 'Orders Panel',
  [ROLE_ACCESSES.viewTrainersProfile]: 'Trainers (Profile)',
  [ROLE_ACCESSES.tciProfile]: 'My TCI Profile',
  [ROLE_ACCESSES.editTrainerProfile]: 'Edit Trainer (Profile)',
  [ROLE_ACCESSES.swapInstructor]: 'Instructor Swap',
  [ROLE_ACCESSES.canDeliver]: 'Deliver Course',
  [ROLE_ACCESSES.canCancel]: 'Cancel Course',
  [ROLE_ACCESSES.cancelMsg]: 'Cancel Course Notification',
  [ROLE_ACCESSES.editEvent]: 'Edit Course',
  [ROLE_ACCESSES.editEventMsg]: 'Edit Course Notification',
  [ROLE_ACCESSES.canManuallyInvoice]: 'Invoice TCI Manually',
  [ROLE_ACCESSES.subcontractorInvMan]: 'Invoice Subcontracted Manually',
  [ROLE_ACCESSES.outsideTerritoryNotification]: 'Disable Outside-Location Notification',
  [ROLE_ACCESSES.showRevenue]: 'Show Revenue',
  [ROLE_ACCESSES.customerDashboard]: 'Customer Dashboard',
  [ROLE_ACCESSES.editTimeblock]: 'Edit Time Blocks',
  [ROLE_ACCESSES.subcontractorEvents]: 'Subcontractor Events',
  [ROLE_ACCESSES.territoryExport]: 'Export Territory',
  [ROLE_ACCESSES.viewNationalAccountPanel]: 'National Account Panel',
  [ROLE_ACCESSES.basicAdmin]: 'Basic Admin',
  [ROLE_ACCESSES.advancedAdmin]: 'Advanced Admin',
};

export const ADMIN_ROLE_ACCESSES = [ROLE_ACCESSES.basicAdmin, ROLE_ACCESSES.advancedAdmin];

export const ADMIN_ACCESSES = {
  tciCert: 'tciCert',
  createTrainingSites: 'createTrainingSites',
  couponCreation: 'couponCreation',
  materials: 'materials',
  courses: 'courses',
  certList: 'certList',
  commissions: 'commissions',
  categories: 'categories',
  matrix: 'matrix',
};

export const ADMIN_ACCESSES_LABELS = {
  [ADMIN_ACCESSES.tciCert]: 'TCI Certification',
  [ADMIN_ACCESSES.createTrainingSites]: 'Create OE Training Sites',
  [ADMIN_ACCESSES.couponCreation]: 'Coupon Creation',
  [ADMIN_ACCESSES.materials]: 'Materials',
  [ADMIN_ACCESSES.courses]: 'Courses',
  [ADMIN_ACCESSES.certList]: 'Certifications List',
  [ADMIN_ACCESSES.commissions]: 'Commission Levels',
  [ADMIN_ACCESSES.categories]: 'Course Categories',
  [ADMIN_ACCESSES.matrix]: 'Role Matrix',
};

export const USER_ROLES = {
  ADMIN: 'ADMIN', // ALL ACCESS
  ISRM: 'ISRM', // Manager of the ISR
  ISR: 'ISR', // Manager oof Instructoor Managers
  TCIM: 'TCIM', // Instructor Manager
  TCI: 'TCI', // Instructor
  XXGM: 'Z&XX_GENERAL_MANAGER', // General Manger with No Org Assignment
  RBD: 'Z&XX_RBD', // RBD - Sales Rep
};

export const ORG_STRUCTURE_ROLES = {
  ADMIN: 'ADMIN', // ALL ACCESS
  TCI: 'TCI', // Instructor
  TCIM: 'TCIM', // Instructor Manager
  TCSSM: 'TCSSM',
  RTSS: 'RTSS',
  TCSS: 'TCSS',
  ISR: 'ISR', // Manager oof Instructoor Managers
  ISRNA: 'ISRNA',
  ISRG: 'ISRG',
  ISRM: 'ISRM', // Manager of the ISR
  ISM: 'ISM',
};

export const SAP_ROLES = {
  ...USER_ROLES,
  ISM: 'ISM',
  ISRNA: 'ISRNA',
  RTSS: 'RTSS',
  RBD: 'RBD',
  ISRG: 'ISRG',
  TCSSM: 'TCSSM',
  TCSS: 'TCSS',
  XXREP: 'Z&XX_SALES_REP_ISR',
  XXSALDM: 'Z&XX_SALES_DIRECTOR_MANAGER',
  SSR: 'Z&XX_SERVICE_REP',
  GM: 'GM',
};

export const NO_GROUPPING_ORG_STRUCTURE = { TCSSM: SAP_ROLES.TCSSM };
export const NO_MAKE_DAY_DISCOUNT_CHANNELS = ['11', '12'];

export const USER_ROLES_SAP_MAPPING = {
  [USER_ROLES.ADMIN]: {
    level: 0,
    sapRoles: [USER_ROLES.ADMIN],
  },
  [USER_ROLES.ISRM]: {
    level: 0,
    sapRoles: [USER_ROLES.ISRM, SAP_ROLES.ISM],
  },
  [USER_ROLES.ISR]: {
    level: 1,
    sapRoles: [SAP_ROLES.ISRNA, SAP_ROLES.RBD, SAP_ROLES.ISRG],
  },
  [USER_ROLES.TCIM]: {
    level: 2,
    sapRoles: [USER_ROLES.TCIM, SAP_ROLES.GM, SAP_ROLES.RBD, NO_GROUPPING_ORG_STRUCTURE.TCSSM],
  },
  [USER_ROLES.TCI]: {
    level: 3,
    sapRoles: [USER_ROLES.TCI, SAP_ROLES.TCSS, SAP_ROLES.RTSS],
  },
  [USER_ROLES.XXGM]: {
    level: 4,
    sapRoles: [USER_ROLES.XXGM, SAP_ROLES.XXSALDM],
  },
  [USER_ROLES.RBD]: {
    level: 5,
    sapRoles: [USER_ROLES.RBD, SAP_ROLES.XXREP, SAP_ROLES.SSR],
  },
};

export const CALENDAR_VIEWS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const CALENDAR_TYPE = {
  tci: undefined, // this is the default value
  location: 'location',
};

export const EVENT_TYPE = {
  ON_SITE: 'ON-SITE',
  OPEN_ENROLLMENT: 'OPEN-ENROLLMENT',
  TIME_BLOCK: 'TIME-BLOCK',
  CANCELLED_EVENT: 'CANCELLED-EVENT',
  SUBCONTRACTED: 'SUBCONTRACTED',
  QUOTE: 'QUOTE',
};

export const EVENT_TYPE_DISPLAY = {
  'ON-SITE': 'ON SITE',
  'OPEN-ENROLLMENT': 'OPEN ENROLLMENT',
  'TIME-BLOCK': 'TIME BLOCK',
  QUOTE: 'QUOTE',
};

export const EVENT_STEPS = {
  'ON-SITE': 2,
  QUOTE: 2,
  'OPEN-ENROLLMENT': 2,
  'TIME-BLOCK': 1,
};

export const ORDER_BLOCKED = {
  allowed: 'A',
  denied: 'D',
};

export const COLORS = {
  CINTAS_BLUE: '#012169',
  CINTAS_BLUE_GRADIENT: '#1C4299',
  CINTAS_LIGHT_BLUE: '#EEF8FF',
  CINTAS_WHITE: '#FFFFFF',
  CINTAS_RED: '#BA0C2F',
  CINTAS_GREEN: 'green',
  CINTAS_ORANGE: '#FFA300',
  CINTAS_YELLOW: 'rgba(252, 235, 166, 1)',
  CINTAS_YELLOW_DARK: 'rgba(115, 120, 11, 1)',
  CINTAS_ORANGE_DARK: '#e69300',
  CINTAS_ORANGE_OPAQUE_RGBA: 'rgba(255, 163, 0, 0.4)',
  CINTAS_BLACK_OPAQUE_RGBA: 'rgba(0, 0, 0, 0.5)',
  CINTAS_RED_OPAQUE_RGBA: 'rgba(186, 12, 47, 0.4)',
  CINTAS_GRAY_OPAQUE_RGBA: 'rgba(222, 222, 222, 0.5)',
  CINTAS_BLUE_OPAQUE_RGBA: 'rgba(1, 33, 105, 0.1)',
  CINTAS_MEDIUM_GRAY_OPAQUE_RGBA: 'rgba(172, 172, 172, 0.5)',
  CINTAS_GRAY: '#ACACAC',
  CINTAS_LIGHT_GRAY: '#DEDEDE',
  CINTAS_DARK_GRAY: '#989898',
  CINTAS_GRAY_BORDER: '#E2E2E2',
  CINTAS_MEDIUM_GRAY: '#777777',
};

export const EVENT_CALENDAR_COLORS = {
  'TIME-BLOCK': '#FFAC1A',
  'OPEN-ENROLLMENT': '#0099CC',
  'ON-SITE': '#42B01C',
  'CANCELLED-EVENT': '#BA0C2F',
  SUBCONTRACTED: COLORS.CINTAS_GRAY,
};

export const CURRENCY_SYMBOLS = {
  CAD: 'CA$',
  USD: '$',
};

export const CURRENCY = {
  CAD: 'CAD',
  USD: 'USD',
};

export const DEFAULT_CURRENCY = CURRENCY.USD;

export const BOOKING_MODE = {
  booking: 'bkng',
  editing: 'edtng',
  rescheduling: 'reschdl',
  rebooking: 'rebkng',
  quote: 'quote',
  duplicate: 'duplicate',
};

/**
 * This is not contant, just a sample data,
 * this data will reset to get and post in API from database
 */
export const CUSTOMEROPTIONS = [
  {
    id: 0,
    businessName: 'Fedex',
    addressLine1: '110 Street',
    addressLine2: '110 Street',
    city: 'Chicago',
    state: { name: 'IL' },
    zipCode: '60601',
  },
  {
    id: 1,
    businessName: 'Fedex1',
    addressLine1: '110 Street',
    addressLine2: '110 Street',
    city: 'Chicago',
    state: { name: 'IL' },
    zipCode: '60601',
  },
  {
    id: 2,
    businessName: 'Fedex2',
    addressLine1: '110 Street',
    addressLine2: '110 Street',
    city: 'Chicago',
    state: { name: 'IL' },
    zipCode: '60601',
  },
];

export const CUSTOMERCONTACTS = [
  {
    id: 0,
    firstName: 'Daniel',
    lastName: 'Brown',
    role: 'Store Manager',
    email: 'Daniel.Brown@company.com',
    phoneNumber: '111-111-1111',
  },
  {
    id: 1,
    firstName: 'Jerry',
    lastName: 'Brown',
    role: 'Store Manager',
    email: 'Daniel.Brown@company.com',
    phoneNumber: '111-111-1111',
  },
  {
    id: 2,
    firstName: 'Tomas',
    lastName: 'Brown',
    role: 'Store Manager',
    email: 'Daniel.Brown@company.com',
    phoneNumber: '111-111-1111',
  },
];

export const BLOCK_REASON = {
  PTO: 'PTO',
  TRAINING: 'TRAINING',
  'RIDE-ALONG': 'RIDE-ALONG',
  'NON-CUSTOMER-CLASS': 'NON-CUSTOMER-CLASS',
  TRAVEL: 'TRAVEL',
  BREAK: 'BREAK',
  'AFTER-HOURS': 'AFTER-HOURS',
  'PHONE-BLOCK': 'PHONE-BLOCK',
  CUSTOMER_ISSUE: 'CUSTOMER_ISSUE',
  OPEN_ENROLLMENT: 'OPEN_ENROLLMENT',
};

export const TIME_BLOCK_REASONS = {
  [BLOCK_REASON['AFTER-HOURS']]: 'After hours in congruent time period',
  [BLOCK_REASON['RIDE-ALONG']]: 'Cintas Partner Ride Along',
  [BLOCK_REASON['NON-CUSTOMER-CLASS']]: 'Class held for new customer',
  [BLOCK_REASON.CUSTOMER_ISSUE]: 'Customer Data Issue',
  [BLOCK_REASON.TRAINING]: 'Location Meeting/Training',
  [BLOCK_REASON.OPEN_ENROLLMENT]: 'Open Enrollment Class',
  [BLOCK_REASON.OTHER]: 'Other',
  [BLOCK_REASON['PHONE-BLOCK']]: 'Phone block',
  [BLOCK_REASON.PTO]: 'PTO',
  [BLOCK_REASON.TRAVEL]: 'Travel',
  [BLOCK_REASON.BREAK]: 'Break',
};

export const TIME_BLOCK_TYPE = {
  FIRM: 'Firm',
  OPTIONAL: 'Optional',
};

export const NOTES_MAX_LENGTH = 1000;
export const DESC_MAX_LENGTH = 1000;

export const OEC_CUSTOMER = 'W5ii3lQEfvkz3g3EUGmH';

export const MATERIAL_TYPE = {
  ADD_ON: 'ADD_ON',
  COURSE: 'COURSE',
  SKILL_CHECK: 'SKILL_CHECK',
  ADDITIONAL_PARTICIPANTS: 'ADDITIONAL_PARTICIPANTS',
  TIME_BLOCK: 'TIME_BLOCK',
};

export const MATERIAL_TYPE_DISPLAY = {
  ADD_ON: 'Add-on',
  COURSE: 'Course',
  SKILL_CHECK: 'Skill Check',
  ADDITIONAL_PARTICIPANTS: 'Additional Participants',
};

export const MATERIAL_STATUS = {
  VISIBLE: 'VISIBLE',
  PARTNERS: 'PARTNERS',
  HIDDEN: 'HIDDEN',
};

export const MATERIAL_STATUS_DISPLAY = {
  VISIBLE: 'Customer-Facing',
  PARTNERS: 'Partners Only',
  HIDDEN: 'Hidden',
};

export const ORDER_STATUS_DISPLAY = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  RESCHEDULED: 'Rescheduled',
  MANUAL_INVOICE: 'Manual Invoice',
};

export const CUSTOMER_ORDERS_TABS = {
  upcoming: 'UPCOMING',
  past: 'PAST',
  quotes: 'QUOTES',
};

export const QUOTES_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const COUNTRIES_ENUM = {
  USA: 'USA',
  CAN: 'CAN',
  MEX: 'MEX',
};

export const COUNTRIES = [
  {
    name: 'United States of America',
    code: COUNTRIES_ENUM.USA,
  },
  {
    name: 'Canada',
    code: COUNTRIES_ENUM.CAN,
  },
  {
    name: 'Mexico',
    code: COUNTRIES_ENUM.MEX,
  },
];

export const US_STATES = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export const CANADIAN_PROVINCES = [
  {
    name: 'Alberta',
    code: 'AB',
  },
  {
    name: 'British Columbia',
    code: 'BC',
  },
  {
    name: 'Manitoba',
    code: 'MB',
  },
  {
    name: 'New Brunswick',
    code: 'NB',
  },
  {
    name: 'Newfoundland and Labrador',
    code: 'NL',
  },
  {
    name: 'Northwest Territories',
    code: 'NT',
  },
  {
    name: 'Nova Scotia',
    code: 'NS',
  },
  {
    name: 'Nunavut',
    code: 'NU',
  },
  {
    name: 'Ontario',
    code: 'ON',
  },
  {
    name: 'Prince Edward Island',
    code: 'PE',
  },
  {
    name: 'Quebec',
    code: 'QC',
  },
  {
    name: 'Saskatchewan',
    code: 'SK',
  },
  {
    name: 'Yukon Territory',
    code: 'YT',
  },
];

export const MEXICAN_STATES = [
  {
    name: 'Distrito Federal',
    code: 'DIF',
    subdivision: 'federal district',
  },
  {
    name: 'Aguascalientes',
    code: 'AGU',
    subdivision: 'state',
  },
  {
    name: 'Baja California',
    code: 'BCN',
    subdivision: 'state',
  },
  {
    name: 'Baja California Sur',
    code: 'BCS',
    subdivision: 'state',
  },
  {
    name: 'Campeche',
    code: 'CAM',
    subdivision: 'state',
  },
  {
    name: 'Chiapas',
    code: 'CHP',
    subdivision: 'state',
  },
  {
    name: 'Chihuahua',
    code: 'CHH',
    subdivision: 'state',
  },
  {
    name: 'Coahuila',
    code: 'COA',
    subdivision: 'state',
  },
  {
    name: 'Colima',
    code: 'COL',
    subdivision: 'state',
  },
  {
    name: 'Durango',
    code: 'DUR',
    subdivision: 'state',
  },
  {
    name: 'Guanajuato',
    code: 'GUA',
    subdivision: 'state',
  },
  {
    name: 'Guerrero',
    code: 'GRO',
    subdivision: 'state',
  },
  {
    name: 'Hidalgo',
    code: 'HID',
    subdivision: 'state',
  },
  {
    name: 'Jalisco',
    code: 'JAL',
    subdivision: 'state',
  },
  {
    name: 'Michoacán',
    code: 'MIC',
    subdivision: 'state',
  },
  {
    name: 'Morelos',
    code: 'MOR',
    subdivision: 'state',
  },
  {
    name: 'México',
    code: 'MEX',
    subdivision: 'state',
  },
  {
    name: 'Nayarit',
    code: 'NAY',
    subdivision: 'state',
  },
  {
    name: 'Nuevo León',
    code: 'NLE',
    subdivision: 'state',
  },
  {
    name: 'Oaxaca',
    code: 'OAX',
    subdivision: 'state',
  },
  {
    name: 'Puebla',
    code: 'PUE',
    subdivision: 'state',
  },
  {
    name: 'Querétaro',
    code: 'QUE',
    subdivision: 'state',
  },
  {
    name: 'Quintana Roo',
    code: 'ROO',
    subdivision: 'state',
  },
  {
    name: 'San Luis Potosí',
    code: 'SLP',
    subdivision: 'state',
  },
  {
    name: 'Sinaloa',
    code: 'SIN',
    subdivision: 'state',
  },
  {
    name: 'Sonora',
    code: 'SON',
    subdivision: 'state',
  },
  {
    name: 'Tabasco',
    code: 'TAB',
    subdivision: 'state',
  },
  {
    name: 'Tamaulipas',
    code: 'TAM',
    subdivision: 'state',
  },
  {
    name: 'Tlaxcala',
    code: 'TLA',
    subdivision: 'state',
  },
  {
    name: 'Veracruz',
    code: 'VER',
    subdivision: 'state',
  },
  {
    name: 'Yucatán',
    code: 'YUC',
    subdivision: 'state',
  },
  {
    name: 'Zacatecas',
    code: 'ZAC',
    subdivision: 'state',
  },
];

export const LIMITED_COUNTRIES = [
  {
    name: 'United States',
    code: 'US',
    states: US_STATES,
  },
  {
    name: 'Canada',
    code: 'CA',
    states: CANADIAN_PROVINCES,
  },
];

export const PRICING_REF_LOCATION = {
  1000: '0F73',
  2000: '033G',
};

export const APP_NAME = 'Course Scheduler';
export const OK_STATUS_CODES = [200, 201];

export const unitsOfTime = {
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
};

export const defaultView = Views.WEEK;
export const CANCELLATION_FEE_MATERIAL = '000000000000065905';
export const AFTER_HOUR_FEE_MATERIAL = '000000000000065908';
export const TRAVEL_FEE_MATERIAL = '000000000000065906';
export const DEFAULT_SIGNATURE_DATE = '1999-12-31';
export const AM_TIME_IDENTIFIER = 'AM';
export const PM_TIME_IDENTIFIER = 'PM';
export const TIME_FORMAT = 'h:mm A';
export const TIME_FORMAT_NO_SPACE = TIME_FORMAT.replaceAll(' ', '');
export const NOTIFICATION_DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const DATE_PICKER_FOMAT = 'yyyy-MM-DD';
export const DATE_TIME_SHORT_FORMAT = 'MMM D, h:mm A';
export const DATE_TIME_YYYY_SHORT_FORMAT = 'MMM D, YYYY h:mm A';
export const DATE_TIME_YYYY_SHORT_FORMAT_HISTORY = 'MMM D, YYYY - h:mm A';
export const DATE_SHORT_FORMAT = 'MMM D, YYYY';
export const DATE_TIME_ISO = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_LONG_FOMAT = 'dddd, MMMM Do YYYY, h:mm A';
export const DAY_LONG_FOMAT = 'dddd, MM/DD/YY';
export const DATE_STANDARD_FORMAT = 'MM/DD/YYYY';
export const DATE_STANDARD_SHORT_FORMAT = 'MM/DD/YY';
export const DATE_NAME_FORMAT = 'dddd';
export const DEFAULT_TRUCK_NUMBER = '0001';
export const ENTER_KEY_CODE = 'Enter';
export const DEFAULT_ROUTE = '0059';
export const CUSTOMER_SEARCH_RESULTS_TRESHOLD = 10000;
export const DATA_LOOKUP_RESULTS_TRESHOLD = 200;
export const FETCH_ORDERS_RESULTS_THRESHOLD = 100;
export const DEFAULT_ROUTE_REGEX = /0059$/;
export const PARTICIPANTS_MARGIN = 5;
export const MAX_PROFILE_IMG_SIZE = 2500000;
export const SUBCONTRACTED_INSTRUCTOR_LABEL = 'External Vendor';
export const TRUCK_NUM_VALIDATION_REGEX = /^(T|t)0\d{2}$/;
export const SPECIAL_TRUCK_NUMS = [DEFAULT_TRUCK_NUMBER];
export const QUOTE_EXPIRATION_DAYS = 30;
export const NOTIFICATION_EXPIRATION_DAYS = 30;
export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_VALIDATION_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const BELOW_MIN_PRICE_CL = {
  id: -1,
  name: 'N/A',
  min: 0,
  max: 1000,
};

export const COMPATIBLE_BROWSERS = [
  {
    name: 'Chrome',
    version: '64',
    date: 'Jan 2018',
  },
  {
    name: 'Edge',
    version: '79',
    date: 'Jan 2020',
  },
  {
    name: 'Firefox',
    version: '78',
    date: 'Jun 2020',
  },
  {
    name: 'Safari',
    version: '12',
    date: 'Sep 2018',
  },
  {
    name: 'Opera',
    version: '51',
    date: 'Feb 2018',
  },
];

export const SIGNATURE_PAD_WIDTH = '704';
export const SIGNATURE_PAD_HEIGHT = '243';

export const TIME_BLOCK_INTERVALS = {
  NO_REPEAT: 'Does not repeat',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const TIME_BLOCK_REPETITION_RULES = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Forth',
};

export const ORDERS_FILTERS_ENUM = {
  customer: 'customer',
  cancelled: 'cancelled',
  tempContact: 'tempContact',
  unsigned: 'unsigned',
  account: 'account',
  region: 'region',
  locations: 'locations',
  startTime: 'startTime',
  endTime: 'endTime',
};

export const ORDERS_FILTERS_LABELS = {
  [ORDERS_FILTERS_ENUM.cancelled]: 'Cancelled Orders',
  [ORDERS_FILTERS_ENUM.tempContact]: 'Has Temporary Contact',
  [ORDERS_FILTERS_ENUM.unsigned]: 'Unsigned Orders',
  [ORDERS_FILTERS_ENUM.region]: 'Region',
  [ORDERS_FILTERS_ENUM.locations]: 'Location',
  [ORDERS_FILTERS_ENUM.startTime]: 'Start Date',
  [ORDERS_FILTERS_ENUM.endTime]: 'End Date',
  [ORDERS_FILTERS_ENUM.account]: 'National Account',
};

export const ORDERS_VIEWS = {
  upcomingCustomer: 'upcomingCustomer',
  pastCustomer: 'pastCustomer',
  nationalAccount: 'nationalAccount',
  ordersPanel: 'ordersPanel',
};

export const ORDERS_TABLE_COLUMNS = {
  region: 'region',
  location: 'location',
  instructorTCI: 'instructorTCI',
  customerName: 'customerName',
  soldTo: 'soldTo',
  po: 'po',
  materials: 'materials',
  scheduledAmount: 'scheduledAmount',
  deliveredAmount: 'deliveredAmount',
  startDate: 'startDate',
  startTime: 'startTime',
  timezone: 'timezone',
  orderStatus: 'orderStatus',
  reason: 'reason',
  route: 'route',
  contactFirstName: 'contactFirstName',
  contactLastName: 'contactLastName',
  contactPhone: 'contactPhone',
  contactEmail: 'contactEmail',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  // storeNum: 'storeNum',
  distributionChannel: 'distributionChannel',
  eventSummaryButton: 'eventSummaryButton',
  editButton: 'editButton',
  orderConfirmationButton: 'orderConfirmationButton',
};

export const ORDERS_TABLE_COLUMNS_LABELS = {
  [ORDERS_TABLE_COLUMNS.region]: 'Region',
  [ORDERS_TABLE_COLUMNS.location]: 'Location',
  [ORDERS_TABLE_COLUMNS.instructorTCI]: 'Instructor / TCI',
  [ORDERS_TABLE_COLUMNS.customerName]: 'Customer Name',
  [ORDERS_TABLE_COLUMNS.soldTo]: 'Sold-To',
  [ORDERS_TABLE_COLUMNS.po]: 'PO',
  [ORDERS_TABLE_COLUMNS.materials]: 'Course Type',
  [ORDERS_TABLE_COLUMNS.scheduledAmount]: 'Scheduled Amount',
  [ORDERS_TABLE_COLUMNS.deliveredAmount]: 'Delivered Amount',
  [ORDERS_TABLE_COLUMNS.startDate]: 'Start Date',
  [ORDERS_TABLE_COLUMNS.startTime]: 'Start Time',
  [ORDERS_TABLE_COLUMNS.timezone]: 'Timezone',
  [ORDERS_TABLE_COLUMNS.orderStatus]: 'Order Status',
  [ORDERS_TABLE_COLUMNS.reason]: 'Reason',
  [ORDERS_TABLE_COLUMNS.route]: 'Route',
  [ORDERS_TABLE_COLUMNS.contactFirstName]: 'Contact First Name',
  [ORDERS_TABLE_COLUMNS.contactLastName]: 'Contact Last Name',
  [ORDERS_TABLE_COLUMNS.contactPhone]: 'Contact Phone',
  [ORDERS_TABLE_COLUMNS.contactEmail]: 'Contact Email',
  [ORDERS_TABLE_COLUMNS.createdBy]: 'Created By',
  [ORDERS_TABLE_COLUMNS.createdAt]: 'Created Date',
  // [ORDERS_TABLE_COLUMNS.storeNum]: 'Store #',
  [ORDERS_TABLE_COLUMNS.distributionChannel]: 'Distribution Channel',
  [ORDERS_TABLE_COLUMNS.eventSummaryButton]: '',
  [ORDERS_TABLE_COLUMNS.editButton]: '',
  [ORDERS_TABLE_COLUMNS.orderConfirmationButton]: '',
};

export const NOTES_DISPLAY_BORDER = '1px solid #D16103';
const defaultTimeout = { duration: 1, unit: 'hour' };
const timeoutSettings = {
  develop: defaultTimeout,
  release: defaultTimeout,
  production: { duration: 1, unit: 'day' },
};
export const SESSION_TIMEOUT = timeoutSettings[process.env.REACT_APP_ENV] ?? defaultTimeout;
export const ENVIRONMENTS_WITH_EMAIL_LOGIN = ['develop', 'release', 'sandbox', 'pre-prod'];
